import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { memo } from 'react';
import Slider from 'react-slick';
import CapterraRating from '@assets/capterra-rating.svg';
import GetAppRating from '@assets/getapp-rating.svg';
import GoogleRating from '@assets/google-rating.svg';
import OmrRating from '@assets/omr-rating.svg';
import styles from './ReviewsCarousel.module.css';

const data = [
    {
        id: 1,
        title: 'Capterra Rating',
        image: CapterraRating.src,
    },
    {
        id: 2,
        title: 'GetApp Rating',
        image: GetAppRating.src,
    },
    {
        id: 3,
        title: 'Google Rating',
        image: GoogleRating.src,
    },
    {
        id: 4,
        title: 'OMR Rating',
        image: OmrRating.src,
    },
];

const ReviewsCarousel = () => {
    return (
        <Slider
            className={styles.reviewsCarousel}
            autoplay
            infinite
            slidesToShow={1}
            slidesToScroll={1}
            arrows={false}
            fade
        >
            {data.map(({ id, title, image }) => (
                <img key={id} className={styles.ratingCarouselImage} src={image} alt={title} />
            ))}
        </Slider>
    );
};

export default memo(ReviewsCarousel);
